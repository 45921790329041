import { useRouter } from 'apis/history';
import * as React from 'react';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';
import { Quiz } from 'types/quiz';
import Logo from 'assets/icons/logo.svg';
import Fb from 'assets/icons/facebook.svg';
import Ig from 'assets/icons/instagram.svg';
import { config } from 'config';
import { useQuizData } from 'utils/hooks';

interface FooterProps {
  disclaimerText: string[];
  whiteFooter?: boolean;
}

const LCFooter: React.FC<FooterProps> = ({
  disclaimerText,
  whiteFooter = false,
}) => {
  const {
    goToPrivacy,
    goToTerms,
    goToContact,
    goToLanding,
    goToFAQ,
    goToReviews,
  } = useRouter();
  const { isTablet } = useQuery();
  const data = useQuizData('pagesLinks');
  return (
    <>
      <Container whiteFooter={whiteFooter}>
        <Wrapper>
          <Links>
            <LinkWrap onClick={() => goToLanding()}>
              <Logo />
            </LinkWrap>
            <LinkWrap onClick={() => goToReviews()}>
              <StyledTextT>{data?.reviews || 'Reviews'}</StyledTextT>
            </LinkWrap>
            <LinkWrap>
              <StyledTextT type="bodyM600" color="light0">
                <StyledLink href="https://help.effecto.app/hc/en-us">
                  {data?.helpCenter || 'Help Center'}
                </StyledLink>
              </StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToContact()}>
              <StyledTextT>{data?.contact || 'Contact Us'}</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT>{data?.terms || 'Terms & Conditions'}</StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT>{data?.privacy || 'Privacy Policy'}</StyledTextT>
            </LinkWrap>
          </Links>

          <DisclaimerContainer>
            <Wrap>
              <IconsContainer>
                <a
                  href="https://www.facebook.com/TheEffectoApp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Fb />
                </a>
                <a
                  href="https://www.instagram.com/effecto.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Ig />
                </a>
              </IconsContainer>
              {disclaimerText.map((paragraph, index) => (
                <StyledText key={index}>
                  {paragraph?.replace(
                    '${year}',
                    new Date().getFullYear().toString(),
                  )}
                </StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default LCFooter;

const IconsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 1.25rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const Dot = styled.div`
  color: #fff;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisclaimerWrap = styled.div`
  cursor: pointer;
`;

const StyledTextT = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

const StyledLink = styled.a`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;

  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  gap: 1.25rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StyledText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

const StyledTextBold = styled(StyledTextT)`
  font-weight: 600 !important;
`;

const DisclaimerContainer = styled.div`
  width: 100%;
  border-top: 1px solid rgba(115, 137, 156, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    gap: 0.5rem;
  }
`;

const Container = styled.div<{ whiteFooter?: boolean }>`
  display: flex;
  padding: 1.5rem 0;
  gap: 1.5rem;
  /* background: ${({ whiteFooter }) => (whiteFooter ? '#fff' : '#F9F9F9')}; */
  border-top: 1px solid rgba(186, 182, 182, 0.2);
  background: #e9f6f2;
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 1.5rem 1rem;
  }
`;

const Links = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media ${tablet} {
    flex-direction: column;
    height: unset;
    gap: 1rem;
    width: 100%;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${tablet} {
  }
`;
